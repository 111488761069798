import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

import { fetchTeam } from '../redux/actions/teamActions';
import userHelper from '../helpers/userHelper';
import APIService from '../helpers/apiService';

import { TeamMember, WherebyEmbed, Chat } from '../components';
import { getAccessToken } from '../helpers/authHeader';

const TeamSpace = () => {
  const dispatch = useDispatch();
  const token = getAccessToken();
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [displayName, setDisplayName] = useState('Guest');
  
  const { isLoading, hasErrors, team } = useSelector(
    ({ team }) => ({
      isLoading: team.isLoading,
      hasErrors: team.hasErrors,
      team: team.team
    })
  );

  useEffect(() => {
    const { name } = jwtDecode(token);
    setDisplayName(name)
  }, [token]);

  useEffect(() => {
    setTeamLoaded(!isLoading && !hasErrors);
  }, [isLoading, hasErrors, setTeamLoaded]);

  useEffect(() => {
    dispatch(fetchTeam());
  }, [dispatch]);

  return (
    <div className="TeamSpace">
      <div className="TeamSpace__grid">
        <h1 className="TeamSpace__grid__title HeaderTitle HeaderTitle--left HeaderTitle--white">Meet your group</h1>
        {teamLoaded && (
          <>
            <WherebyEmbed className={`TeamSpace__grid__room ${team.showChat ? 'TeamSpace__grid__room--with-chat' : ''}`} url={team.roomUrl} roomType="videoCall" displayName={displayName} />
            {team.showChat && (
              <div className="Chat TeamSpace__grid__chat">
                <Chat key={`Team-Chat-${team.id}`} room={`/team/${team.TeamId}`} TeamId={team.TeamId} />
              </div>
            )}
          </>
        )}

        <div className="TeamSpace__grid__team-members">
          {teamLoaded ? team.members.map(user =>
            <TeamMember classes="TeamMember" key={`team-member-${user.id}`} >
              <img className="TeamMember__image" src={user.ProfilePicture ? user.ProfilePicture.url : process.env.REACT_APP_MISSING_IMAGE_URL}  alt={userHelper.name(user)} />
              <div className="TeamMember__summary">
                <h2 className="TeamMember__summary__name">{userHelper.name(user)}</h2>
                {user.role && <p className="TeamMember__summary__role">{user.role}</p>}
                {user.location && <p className="TeamMember__summary__location">{user.location}</p>}
              </div>
            </TeamMember>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default TeamSpace;
